.campaign-stage-div * {
    display: inline-block;
    flex: 1;
    margin-left: 5px;
}

.campaign-stage-div {
    display: flex;
    flex-direction: row;
    padding-top: 10px;
}

.right-creator-div {
    display: inline-block;
    float: right;
}

.invite-creator-btn {
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
}

.invite-modal {
    width: 500px;
    margin: auto;
    min-height: 400px;
    margin-top: 15vh;
    background-color: white;
    border: none;
    border-radius: 15px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

.invite-modal-header-div {
    justify-content: center;
    display: flex;
    margin-bottom: 25px;
}

.creator-row-div * {
    vertical-align: top;
}

.notes-textarea {
    width: 100%;
    height: 150px;
}

.creator-notes-div {
    display: inline-block;
    margin-left: 25px;
    overflow: scroll;
    height: 80px;
    width: 30%;
}

.approve-deny-div {
    display: inline-block;
    margin-left: 25px;
    overflow: scroll;
    height: 80px;
    align-content: center;
}

.aesthetics-div {
    white-space: nowrap;
    overflow: scroll;
    display: inline-block;
    width: calc(100% - 120px);
    vertical-align: middle;
}

.aesthetic-p {
    display: inline-block;
    margin-left: 10px;
}

.vertical-middle {
    vertical-align: middle;
}

.creators-content-div {
    margin-left: 25px;
}

.creator-notes-textarea {
    height: 70px;
    border: 1px solid #b8cbf5;
    border-radius: 5px;
    width: 80%;
    margin-top: 2px;
    margin-left: 10%;
}

.active-status-dropdown {
    padding: 10px;
    margin-top: 15px;
    float: right;
    border: 1px solid #b8cbf5;
    border-radius: 5px;
    width: 150px;
}

.stages-div {
    overflow: scroll;
    height: calc(100vh - 380px);
}

.approve-icon {
    width: 40px;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
}