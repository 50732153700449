.content-container {
    position: relative;
}

.content-container-div {
    position: relative;
    padding-bottom: 20px;
}

.content-module-container {
    width: 100%;
    padding-left: 1.25%;
}

.image-column:first-child {
    display: inline-block;
    width: 19%;
    margin-left: 1.25%;
    vertical-align: top;
    position: relative;
}

.image-column {
    display: inline-block;
    width: 19%;
    margin-left: .625%;
    vertical-align: top;
    position: relative;
}

.content-image {
    margin-top: 10px;
    width: 100%;
    height: auto;
    border-radius: 17.5px;
}

.video-wrapper {
    border-radius: 17.5px !important;
    margin-top: 10px;
}

.content-video {
    overflow: hidden;
    border-radius: 35px !important;
    padding-top: 10px;
}

.collection-modal {
    width: 275px;
    height: 325px;
    background-color: white;
    border: none;
    border-radius: 15px;
}

.collection-box {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 270px;
    height: 325px;
    background-color: white;
    border-radius: 15px;
    z-index: 10;
}

.first-collection-box {
    position: absolute;
    top: 50px;
    left: 80%;
    width: 270px;
    height: 325px;
    background-color: white;
    border-radius: 15px;
    z-index: 10;
}

.create-collection-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    border-top: 2px solid #396de0;
    padding-top: .5vh;
    padding-bottom: .5vh;
    display: flex;
    align-items: center;
}

.collection-search {
    padding-top: 10px;
    padding-bottom: 10px;
}

.collection-search input {
    margin-left: 5%;
    width: 90%;
    border-radius: 20px;
    border: 2px solid #396de0;
    background: white;
    height: 30px;
    padding-left: 10px;
}

.collection-search input, input::placeholder, select, textarea {
    color: #396de0;
}

.collection-plus {
    display: inline-block;
    height: 6.5vh;
    width: 6.5vh;
    background-color: #C6CA53;
    border-radius: 15px;
    margin-left: 5%;
    position: relative;
}

.collection-plus img {
    width: 15px;
    height: 15px;
    left: calc(50% - 7.5px);
    top: calc(50% - 7.5px);
    position: absolute;
}

.create-collection-footer h5 {
    display: inline-block;
    margin-left: 2.5%;
    margin-bottom: 0;
    font-weight: 700;
}

.back-arrow {
    display: inline-block;
    height: 25px;
    width: auto;
    background-color: transparent;
    border-radius: 15px;
    margin-left: 5%;
}

.create-button {
    position: absolute;
    right: 0;
    margin-right: 15px;
    border-radius: 15px;
    margin-bottom: 5px;
    display: inline-block;
}

.image-preview {
    height: 7.5vh;
    width: 7.5vh;
    object-fit: cover;
    display: inline-block;
    border-radius: 5px;
}

.new-collection-header {
    padding-top: 10px;
    margin-left: 10%;
}

.new-collection-header h5 {
    display: inline-block;
    margin-left: 10px;
}

.collection-input {
    margin-left: 10%;
    width: 80%;
    margin-top: 10px;
}

.collection-input input {
    background-color: #EFEFEF;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
}

.collection-input input textarea {
    color: #737373;
}

.collection-input input textarea::placeholder {
    color: #737373;
}

.collection-input textarea {
    background-color: #EFEFEF;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding-left: 10px;
    height: 120px;
    resize: none;
}

.collection-preview {
    width: 100%;
    height: 50px;
    position: relative;
    margin-top: 5px;
}

.collection-preview img {
    object-fit: cover;
    width: 50px;
    height: 50px;
    display: inline-block;
    border-radius: 10px;
    margin-left: 10px;
}

.collection-preview h5 {
    display: inline-block;
    margin-left: 10px;
    font-weight: 700;
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    -ms-transform: translateY(50%);
    transform: translateY(50%);
}

.add-to-collection-btn {
    position: absolute;
    float: right;
    right: 10px;
    top: 45%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 20px;
    visibility: hidden;
    background-color: #396de0;
    color: white;
    border: none;
    height: 30px;
    width: 60px;
    font-weight : bold ;
}

.remove-from-collection-btn {
    position: absolute;
    float: right;
    right: 10px;
    top: 45%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 20px;
    visibility: hidden;
    background-color: red;
    color: white;
    border: none;
    height: 30px;
    width: 80px;
    font-weight : bold ;
}

.collection-preview:hover button {
    visibility: visible;
}

.collection-preview:hover h5 {
    width: 130px;
}

.collection-preview:hover .shorter-h5 {
    width: 110px !important;
}

.content-modal {
    position: absolute;
    width: 50%;
    min-width: 500px;
    max-height: 90vh;
    background-color: white;
    border: none;
    border-radius: 15px;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.content-summary {
    display: flex;
    margin-left: 2.5vh;
    min-height: 350px;
}

.selected-content {
    display: inline-block;
    flex: 1;
    margin-right: 2.5vh;
}

.selected-content img {
    width: 100%;
    height: auto;
    border-radius: 25px;
    margin-top: 2.5vh;
    margin-bottom: 2.5vh;
    max-height: 80vh;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}

.content-info {
    display: inline-block;
    flex: 1;
    position: relative;
    margin-top: 30px;
}

.aesthetics {
    margin-top: 50px;
}


.labels {
    margin-top: 45px;
}

.labels a {
    border: none;
    margin-right: 10px;
    background-color: #efefef;
    padding: 0px 8px 0px 8px;
    margin-bottom: 10px;
}

.labels fragment a:hover {
    background-color: lightgrey;
}

.download-btn {
    position: absolute;
    right:    10px;
    bottom:   2.5vh;
}
.user-div {
    border-bottom: 1px black solid;
    max-width: 90%;
    padding-bottom: 10px;
    display: flex;
    flex-direction:row;
}

.user-div:hover {
    cursor: pointer;
}

.user-div-preview {
    width: 100%;
}

.user-div-preview:hover {
    cursor: pointer;
}


.summary-profile-img {
    height: 5vh;
    width: 5vh;
    background-position: center center;
    background-repeat: no-repeat;
    display: inline-block;
    border-radius: 50%;
    object-fit: cover;
}

.user-div h5 {
    display: inline-block;
    margin-left: 10px;
}

.content-overlay-div {
    opacity: 0;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #182f60;
    border-radius: 17.5px;
    overflow: hidden;
}

.video-content-overlay-div {
    opacity: 0;
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    bottom: 5px;
    background-color: #182f60;
    border-radius: 17.5px;
    overflow: hidden;
}

.content-overlay-div:hover {
    opacity: 1;
    background-color: rgba(24, 47, 96, .7);
}

.video-content-overlay-div:hover {
    opacity: 1;
    background-color: rgba(24, 47, 96, .7);
}

.plus-icon {
    position: absolute;
    width: 20px;
    height: auto;
    top: 15px;
    right: 40px;
    background-color: transparent;
}

.like-icon {
    position: absolute;
    width: 20px;
    height: auto;
    top: 15px;
    right: 15px;
    background-color: transparent;
}

.plus-icon:hover {
    cursor: pointer;
}

.like-icon:hover {
    cursor: pointer;
}

.download-icon {
    position: absolute;
    width: 20px;
    height: auto;
    top: 15px;
    left: 15px;
    background-color: transparent;
}

.download-icon:hover {
    cursor: pointer;
}

.edit-icon {
    position: absolute;
    width: 30px;
    height: auto;
    top: 10px;
    left: 10px;
    background-color: transparent;
}

.edit-icon:hover {
    cursor: pointer;
}

.content-profile-image {
    width: 3vw;
    height: 3vw;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    bottom: 10px;
    left: 10px;
}

.content-profile-name {
    position: absolute;
    bottom: 25px;
    left: calc(3vw + 20px);
    color: white;
    margin-bottom: 0;
    font-size: 20px;
}

.content-profile-username {
    position: absolute;
    bottom: 12px;
    left: calc(3vw + 20px);
    color: white;
    margin-bottom: 0;
    font-size: 12px;
}

.likes-img {
    width: 15px;
    height: auto;
    display: inline-block;
    margin-left: 15px;
    margin-right: 5px;
}

.stats-div {

}

.add-collection-div {
    overflow: scroll;
    height: 205px;
}

.collection-options-div {
    position: absolute;
    z-index: 10;
    background-color: white;
    top: 40px;
    right: 25px;
    width: 160px;
    border-radius: 10px;
    padding: 10px;
}

.collection-options-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.option-div:first-of-type {
    margin-bottom: 5px;
}

.option-div {
    vertical-align: middle;
}

.option-div * {
    display: inline-block;
    margin-bottom: 0;
}

.option-div:hover {
    background-color: #f2f1f9;
}

.confirmation-modal {
    background-color: white;
    position: absolute;
    border-radius: 25px;
    width: 350px;
    height: 200px;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}


.confirmation-cancel-btn {
    position: absolute;
    left: 20px;
    bottom: 15px;
}

.confirmation-confirm-btn {
    position: absolute;
    right: 20px;
    bottom: 15px;
}

.confirmation-div {
    position: relative;
    height: 200px;
    padding: 10px;
    padding-top: 40px;
    text-align: center;
}

.confirmation-div p {
    margin-top: 20px;
    color: #737373;
}

.portfolio-info-div h3 {
    margin-top: 15px;
}

.portfolio-info-div p {
    margin-top: 30px;
}

.content-aesthetics-div {
    flex-wrap: wrap;
    display: flex;
}

.top-button {
    position: fixed;
    right: 25px;
    bottom: 25px;
    width: 50px;
    height: 50px;
}

.content-relative-div {
    position: relative;
}

.aesthetic-button {
    border-radius: 25px;
    background-color: #c2ba50;
    color: white;
    border: none;
    padding: 5px 10px 5px 10px;
    margin-right: 5px;
    text-decoration: none;
    white-space: nowrap;
    margin-top: 10px;
}