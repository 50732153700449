.business-overview-div {
    display: inline-block;
    width: 32.5%;
    height: 30vh;
    vertical-align: top;
    margin-left: 2.5%;
}

.business-info-div {
    height: 18vh;
}

.notifications-div {
    height: calc(20vh + 40px);
    padding-left: 15px;
    padding-right: 15px;
}

.notification-h {
    padding-bottom: 10px;
    border-bottom: 1px solid #b8cbf5;
    margin-top: 2%;
}

.campaign-hub-div {
    display: inline-block;
    width: 60%;
    vertical-align: top;
    margin-left: 1.5%;
}

.campaign-hub-div h3 {
    margin-left: 2.5%;
    margin-top: 0.7%;
}

.hub-top-container {
    margin-top: 20px;
}

.hub-creator-div {
    display: inline-block;
    background: white;
    border: 1px solid #b8cbf5;
    margin-bottom: 15px;
    border-radius: 15px;
    padding: 10px;
    margin-right: 15px;
    position: relative;
    width: 200px;
    height: 320px;
    vertical-align: top;
}

.brand-logo-div {
    width: 40%;
    display: inline-block;
    margin-top: 2%;
}

.brand-info-div {
    width: 55%;
    margin-left: 5%;
    display: inline-block;
    vertical-align: top;
    margin-top: 2%;
}

.campaign-visibility-div * {
    float: right;
    margin-right: 5px;
}

.margin-right-15 {
    margin-right: 15px;
}

.pencil-img {
    width: 25px;
    height: auto;
}

.brand-pencil-img {
    width: 20px;
    height: auto;
    cursor: pointer;
}

.absolute-10-10 {
    position: absolute;
    top: 10px;
    right: 10px;
}

.business-edit-modal {
    width: 800px;
    margin: auto;
    min-height: 450px;
    margin-top: 15vh;
    background-color: white;
    border: none;
    border-radius: 15px;
    padding-bottom: 5px;
    position: relative;
}

.logo-preview {
    width: 175px;
    height: 175px;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    object-fit: cover;
}

.logo-preview:hover {
    cursor: pointer;
}

.relative-div {
    position: relative;
}

.at-span {
    position: absolute;
    left: 20px;
    top: 10px;
    z-index: 999;
}

.at-input {
    padding-left: 30px;
}

.edit-modal-div {
    margin: 25px;
}

.social-col {
    width: 30%;
}

.logo-img {
    width: 13vh;
    height: 13vh;
    border-radius: 50%;
    margin-left: 15px;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}

.creator-aesthetics-div {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}
.creator-aesthetics-div * {
    display: inline-block;
    background-color: #396de0;
    border-radius: 15px;
    color: white;
    padding: 0 10px 0 10px;
    font-size: 10px;
    margin-right: 5px;
}

.creator-section-p {
    margin-bottom: 0;
    font-size: 12px;
}

.creator-content-preview-img {
    width: auto;
    height: auto;
    max-height: 10vh;
    max-width: 10vw;
    border-radius: 10px;
}

.creator-content-div {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.creator-info-div {
    display: inline-block;
    width: calc(100% - 95px);
    overflow: hidden;
}

.creator-header-div * {
    vertical-align: top;
    margin-bottom: 5px;
}

.creator-hub-preview-img {
    width: 80px;
    height: 80px;
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 5px;
    margin-right: 10px;
}

.username-p {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: bold;
    color: #396de0;
}

.follower-p {
    font-size: 12px;
    margin-bottom: 0;
}

.creators-spotlight-div {
    /* width: calc(100% - 20px); */
    /* overflow: scroll; */
    white-space: nowrap;
    margin-left: 2.5%;
}

.campaigns-div {
    max-height: 40vh;
    overflow: scroll;
}

.brand-h {
    color: #396de0;
}

.brand-socials-div {
    width: 100%;
    justify-content: center;
    display: flex;
    margin-top: 10px;
}

.brand-social-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.edit-profile-footer {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.edit-profile-footer button {
    margin-left: 5px;
    margin-right: 5px;
}

.edit-business-header-div {
    justify-content: center;
    display: flex;
}

.brandhub-h {
    margin-left: 15px;
}

.campaign-name-h {
    margin-left: 10px;
    color: #737373;
}

.padding-5 {
    padding: 7px;
}

.margin-0 {
    margin: 0;
}

/* .notification-div {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f2f1f9;
    padding-bottom: 5px;
} */

.notification-div {
    position: relative;
    overflow: hidden;
  }

.notification-content {
    display: flex;
    align-items: flex-start;
  }

.notifications-scroll-div {
    overflow: scroll;
    height: calc(20vh - 30px);
}

.notification-info-div {
    display: inline-block;
    margin-left: 10px;
}

.campaign-notification-text-div {
    /*white-space: nowrap;*/
    /*text-overflow: ellipsis;*/
}

.campaign-notification-text-div p {
    display: inline-block;
    margin-bottom: 0;
}

.bullet-span {
    background-color: #b8cbf5;
    border-radius: 50%;
    width: 7px;
    height: 7px;
    display: block;
    flex-shrink: 0;
    align-self: center;
}

.elapsed-time-p {
    font-size: 10px;
}

.bold-p {
    font-weight: bold;
}

.p-14 {
    font-size: 14px;
}