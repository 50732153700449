@import url('https://use.typekit.net/mjw0jrk.css');
@import url('https://fonts.googleapis.com/css?family=Lato');

body, html {
  font-family: 'lato', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pointer:hover {
  cursor: pointer;
}

.gold-btn {
  background-color: #c2ba50;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 5px 20px 5px 20px;
  font-weight : bold;
}

.small-image {
  width: 30px;
  height: 30px;
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.blue-btn {
  background-color: #396de0;
  border: 2px solid #396de0;
  color: white;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

.blue-btn:hover {
  background-color: white;
  border: 2px solid #396de0;;
  color: #396de0;;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

.blue-inverse-btn {
  background-color: white;
  border: 2px solid #396de0;;
  color: #396de0;;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

.blue-inverse-btn:hover {
  background-color: #396de0;
  border: 2px solid #396de0;
  color: white;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

.light-blue-btn {
  background-color: #b8cbf5;
  border: 2px solid #b8cbf5;
  color: #396de0;
  border-radius: 25px;
  padding: 5px 10px 5px 10px;
  font-weight : bold;
}

.blue-text {
  color: #396de0;
}

.font-medium {
  font-family: 'forma-djr-display', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.font-bold {
  font-family: 'forma-djr-display', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.forma-font {
  font-family: 'forma-djr-display', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.nav-header-div {
  width: 100%;
  background: #fcf5e8;
  height: 50px;
}

.nav-header-div h4{
  color: #396de0;
  margin-left: 25px;
  padding-top: 10px;
  margin-bottom: 0;
}

::-webkit-scrollbar {
  display: none;
}

.white-container-div {
  background: white;
  border: 1px solid #b8cbf5;
  margin-bottom: 15px;
  border-radius: 15px;
  padding: 5px;
  position: relative;
}

.container-label-div {
  width: 95%;
  margin-bottom: 15px;
  margin-left: 2.5%;
  margin-top: 15px;
  position: relative;
}

.bottom-right-btn {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.bottom-left-btn {
  position: absolute;
  left: 10px;
  bottom: 5px;
}

.small-icon-img {
  height: 30px;
  width: auto;
}

.grey-input {
  background-color: #EFEFEF;
  border: none;
  border-radius: 5px;
  width: calc(100% - 30px);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
}

.grey-input textarea {
  color: #737373;
}

.grey-input::placeholder {
  color: #737373;
}

.grey-textarea {
  background-color: #EFEFEF;
  color: #737373;
  border: none;
  border-radius: 5px;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  margin-bottom: 10px;
  resize: none;
}

textarea {
  resize: none;
}

.light-grey-btn {
  background-color: #EFEFEF;
  color: #737373;
  border: none;
  border-radius: 25px;
  padding: 5px 20px 5px 20px;
  font-weight : bold;
}

.light-grey {
  color: #737373;
}

.react-switch {
  vertical-align: middle;
  margin-left: 4px;
}