.creator-search-div {
    width: 100%;
    height: 100vh;
    overflow: scroll;
}

.content-preview-img {
    width: auto;
    height: auto;
    max-height: 20vh;
    max-width: 20vw;
    margin-left: 5px;
    border-radius: 10px;
}

.content-preview-div {
    overflow: auto;
    white-space: nowrap;
    display: inline-block;
    width: calc(100% - 30px);
}

.content-preview-div div {
    vertical-align: top;
}

.settings-nav-bar {
    padding-left: 25px;
    padding-right: 10px;
    width: calc(100% - 35px);
    padding-top: 5px;
    height: 55px;
    background: white;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    position: relative;
}

.settings-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
}

.setting-div {
    flex: 1;
}

.setting-div p {
   margin-bottom: 0;
}
.setting-div p:last-child {
    margin-top: -5px;
}

.setting-div select {
    width: 90%;
}

.settings-down-img {
    width: 30px;
    height: auto;
    position: absolute;
    left: calc(50% - 40px);
    bottom: -15px;
    background: white;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
}

.expanded-settings-div {
    height: 200px;
}

.campaign-dropdown-div {
    position: absolute;
    right: 155px;
    border-radius: 15px;
    background-color: white;
    padding: 10px;
    overflow-y: scroll; 
    max-height: 270px;
    /* Scrollbar for non-webkit supporting browsers */
    scrollbar-width: thin; 
    scrollbar-color: #b8b8b8 #f1f1f1;
}

/* Custom scrollbar styles for webkit supported browsers*/
.campaign-dropdown-div::-webkit-scrollbar {
    width: 8px;
    height: 15px;
}

.campaign-dropdown-div::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

.campaign-dropdown-div::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px; 
}

.campaign-dropdown-div::-webkit-scrollbar-thumb:hover {
    background-color: #555; 
}

.campaign-preview-div img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    display: inline-block;
}

.pagination-div {
    margin-left: 30px;
    padding-bottom: 50px;
}

.pagination-div * {
    display: inline-block;
    margin-right: 10px;
}

.page-btn {
    border: none;
    color: #396de0;
}

.selected-page-btn {
    border: none;
    color: #396de0;
    font-weight: 900;
    border-bottom: 2px solid black;
}

.loading-icon {
    position: fixed;
    width: 50px;
    height: 50px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}