.post-container {
    width: 15vw;
    height: 15vw;
    display: inline-block;
    border: 1px solid grey;
}

.grid-container {
    #margin-left: calc(50% - 22.5vw);
    width: calc(45vw + 6px);
    margin-top: 50px;
    border: 1px solid black;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: inline-block;
}

.small-grid-margin {
    margin-left: 2.5vw;
}

.large-grid-margin {
    margin-left: 15vw;
}

.grid-img {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}

.instagram-grid-header-div {
    border-bottom: 1px solid black;
    position: relative;
}

.instagram-grid-header-div * {
    display: inline-block;
}

.add-post-img {
    height: 40px;
    width: auto;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
}

.grid-content-div {
    height: 100vh;
    width: 30vw;
    background-color: lightgrey;
    display: inline-block;
    vertical-align: top;
    right: 0;
    position: fixed;
    overflow: scroll;
}

.new-content-img {
    height: 20vh;
    width: auto;
    margin-right: 10px;
    margin-bottom: 20px;
}

.new-content-div {
    display: inline-block;
}

.new-content-header-div * {
    display: inline-block;
}

.new-content-header-div h3:first-child {
    margin-left: 5px;
    margin-right: 20px;
    cursor: pointer;
}

.facebook-profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: inline-block;
}

.profile-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: inline-block;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
}