.third-col {
    width: 30%;
    display: inline-block;
    vertical-align: top;
}

.white-col-div {
    background: white;
    border: 1px solid #b8cbf5;
    margin-bottom: 15px;
    border-radius: 15px;
    margin-left: 15px;
    padding: 5px;
    margin-top: 10px;
}

.two-third-col {
    width: 63%;
    display: inline-block;
    vertical-align: top;
    background: white;
    border: 1px solid #b8cbf5;
    margin-bottom: 15px;
    border-radius: 15px;
    margin-left: 15px;
    padding: 5px;
    position: relative;
    margin-top: 10px;
}

.full-page {
    height: calc(100vh - 120px);
    width: calc(95% - 40px);
    overflow: scroll;
    margin-top: 15px;
    margin-bottom: 30px;
    position: relative;
    padding: 20px;
}

.flex-form {
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 95%;
}

.flex-form div {
    flex: 1;
}

.form-divider {
    margin-top: 15px;
    border-bottom: 1px solid #396de0;
    padding-bottom: 5px;
}

.notification-border {
    border: 1px solid grey;
    border-radius: 5px;
}

.new-deliverable-btn {
    background-color: #396de0;
    border: 2px solid #396de0;
    color: white;
    border-radius: 25px;
    padding: 5px 10px 5px 10px;
    font-weight : bold;
}

.task-network-icon-img {
    height: 30px;
    width: auto;
    display: inline-block;
}

.task-table {
    margin-bottom: 10px;
}

.task-table tr {
    border-bottom: 1px solid #396de0;
    padding-bottom: 5px;
    padding-top: 5px;
}

.edit-task-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #396de0;
}

.campaign-cover-img {
    width: 100%;
    height: auto;
}